export function getDetailedUser(session: any, authPartnerId?: any) {
  const user = session.user;
  const roles = user?.roles;

  const userPermissions =
    roles?.rolesPartnerContact.roles?.length > 0 && authPartnerId
      ? roles?.rolesPartnerContact.roles.find(
          (el) => el.partnerId === authPartnerId
        )
      : roles?.rolesBroadcomContact
      ? roles?.rolesBroadcomContact[0].roles
      : [];

  const selfPartner = roles?.rolesBroadcomContact
    ? roles?.rolesBroadcomContact[0].roles.viewAllPartners === 'FULL'
      ? []
      : roles?.rolesPartnerContact.roles.filter((el) =>
          el.name.includes('Partner')
        ).length > 0
      ? roles?.rolesPartnerContact?.partnerId
      : []
    : [];

  const selfKnight =
    !selfPartner?.length && roles?.rolesBroadcomContact
      ? roles?.rolesBroadcomContact[0].roles.knight === 'FULL'
        ? []
        : roles?.rolesKnightContact?.knightId.length > 0
        ? roles?.rolesKnightContact?.knightId
        : []
      : [];

  const allocatedPartners =
    roles?.rolesBroadcomContact &&
    roles?.rolesBroadcomContact[0].roles.name.includes(
      'Broadcom Partner Account Director'
    )
      ? roles?.rolesBroadcomContact?.filter((el) => el.partnerId)
      : [];

  const isPartnerAdmin =
    userPermissions.partner === 'EDIT' ||
    (userPermissions.partner === 'VIEW' &&
      roles?.rolesBroadcomContact?.[0]?.roles?.name?.startsWith('Partner'));

  const isSiteAdmin =
    userPermissions.partner === 'FULL' ||
    (userPermissions.partner === 'VIEW' &&
      roles?.rolesBroadcomContact?.[0]?.roles?.name?.startsWith('Broadcom'));

  const isProductForecastManager =
    userPermissions.productForecast === 'FULL' &&
    roles?.rolesBroadcomContact?.[0]?.roles?.name?.startsWith('Product');

  const isPartnerAccountDirector =
    roles?.rolesBroadcomContact?.[0]?.roles?.name ===
    'Broadcom Partner Account Director';

  return {
    user,
    userPermissions: { ...userPermissions, loaded: true,  productForecast:
      user?.email === 'sameer.bhimji@broadcom.com'
        ? 'FULL'
        : userPermissions.productForecast },
    selfPartner,
    selfKnight,
    allocatedPartners,
    isPartnerAdmin,
    isSiteAdmin,
    isProductForecastManager,
    isPartnerAccountDirector
  };
}
