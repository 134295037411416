'use client';
import { createContext, useState } from 'react';
import * as ga from '../lib/ga';

const UseCasesContext = createContext();

const validUseCasesFinderFilters = [
  'complexSearchUseCase',
  'regions',
  'businessUnits',
  'services',
  'verticals',
  'useCaseType',
  'readerType',
  'sort'
];

export function UseCasesContextProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [randomized, setRandomized] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [randomizedRelated, setRandomizedRelated] = useState(null);

  const [relatedPageFiltering, setRelatedPageFiltering] = useState(0);
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [onEditor, setOnEditor] = useState(false);

  // dahsboard pagination
  const [currentPageDashboard, setCurrentPageDashboard] = useState(0);
  const [currentPageModerationDashboard, setCurrentPageModerationDashboard] =
    useState(0);
  const [currentPageDraftDashboard, setCurrentPageDraftDashboard] = useState(0);

  const totalFilters = Object.entries(filters)
    ?.filter((el) => validUseCasesFinderFilters.includes(el[0]))
    ?.reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  const visibleTotalFilters = Object.entries(filters)
    ?.filter((el) =>
      validUseCasesFinderFilters.filter((f) => f !== 'sort').includes(el[0])
    )
    ?.reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function clearUseCasesFilters() {
    setFilters({});
  }
  function clearUseCasesFiltersExceptSearch() {
    if (filters.complexSearchUseCase)
      setFilters({ complexSearchUseCase: filters.complexSearchUseCase });
    else setFilters({});
  }

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };

      if (prop === 'complexSearchUseCase') {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      } else if (
        [
          'businessUnits',
          'services',
          'verticals',
          'regions',
          'useCaseType'
        ].includes(prop)
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      }
      return objCopy;
    });
  }

  function clearFinderToolRandomization() {
    setRandomized(null);
    setRandomizedRelated(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
    setRelatedPageFiltering(0);
  }

  function clearDashboardPagination() {
    setCurrentPageDashboard(0);
    setCurrentPageModerationDashboard(0);
    setCurrentPageDraftDashboard(0);
  }

  const context = {
    filters,
    setFilters,
    updateFilters,
    clickOnFilter,
    clearUseCasesFilters,
    clearUseCasesFiltersExceptSearch,
    validUseCasesFinderFilters,
    currentPage,
    setCurrentPage,
    randomized,
    setRandomized,
    isLoading,
    setIsLoading,
    selectedUseCase,
    setSelectedUseCase,
    totalFilters,
    visibleTotalFilters: visibleTotalFilters,
    relatedPageFiltering,
    setRelatedPageFiltering,
    currentPageFiltering,
    setCurrentPageFiltering,
    randomizedRelated,
    setRandomizedRelated,
    onEditor,
    setOnEditor,
    clearFinderToolRandomization,
    clearFinderToolPagination,
    currentPageDashboard,
    currentPageModerationDashboard,
    currentPageDraftDashboard,
    setCurrentPageDashboard,
    setCurrentPageModerationDashboard,
    setCurrentPageDraftDashboard,
    clearDashboardPagination
  };

  return (
    <UseCasesContext.Provider value={context}>
      {children}
    </UseCasesContext.Provider>
  );
}

export default UseCasesContext;
