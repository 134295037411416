'use client';
import { createContext, useContext, useState } from 'react';

import * as ga from '../lib/ga';

const KnightsContext = createContext();

const validKnightsFinderFilters = [
  'expertise',
  'regions',
  'complexSearchKnight',
  'types'
];

export function KnightsContextProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [f, setF] = useState('nan');
  const [state, setState] = useState({ status: '', invalidFields: [] });
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [relatedPageFiltering, setRelatedPageFiltering] = useState(0);
  const [currentPageKnight, setCurrentPageKnight] = useState(0);
  const [knightViewType, setKnightViewType] = useState('list');
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [currentPageLogger, setCurrentPageLogger] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [randomizedExpert, setRandomizedExpert] = useState(null);
  const [randomizedNoExpert, setRandomizedNoExpert] = useState(null);
  const [randomizedRelated, setRandomizedRelated] = useState(null);
  const [randomizedRelatedNoExpert, setRandomizedRelatedNoExpert] =
    useState(null);

  const [onEditor, setOnEditor] = useState(false);

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };

      if (prop === 'complexSearchKnight') {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      } else if (
        prop === 'knights_contentStatus' ||
        prop === 'knights_locations' ||
        prop === 'knights_products' ||
        prop === 'knights_type'
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      }

      return objCopy;
    });
  }

  function clearKnightsFilters() {
    setFilters({});
  }

  function clearKnightFiltersExceptSearch() {
    if (filters.complexSearchKnight)
      setFilters({ complexSearchKnight: filters.complexSearchKnight });
    else setFilters({});
  }

  function clearFinderToolRandomization() {
    setRandomizedExpert(null);
    setRandomizedNoExpert(null);
    setRandomizedRelated(null);
    setRandomizedRelatedNoExpert(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
    setRelatedPageFiltering(0);
  }

  const totalFilters = Object.entries(filters)?.reduce((total, filter) => {
    return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
  }, 0);

  const visibleTotalFilters = totalFilters;

  const context = {
    filters,
    totalFilters,
    visibleTotalFilters,
    f,
    setF,
    state,
    setState,
    loadingInfo,
    setLoadingInfo,
    updateFilters,
    setFilters,
    clickOnFilter,
    clearKnightsFilters,
    clearKnightFiltersExceptSearch,
    currentPageFiltering,
    setCurrentPageFiltering,
    currentPageKnight,
    setCurrentPageKnight,
    knightViewType,
    setKnightViewType,
    currentPageLogger,
    setCurrentPageLogger,
    relatedPageFiltering,
    setRelatedPageFiltering,
    isOpen,
    setIsOpen,
    randomizedExpert,
    setRandomizedExpert,
    randomizedNoExpert,
    setRandomizedNoExpert,
    randomizedRelated,
    setRandomizedRelated,
    randomizedRelatedNoExpert,
    setRandomizedRelatedNoExpert,
    validKnightsFinderFilters,
    onEditor,
    setOnEditor,
    clearFinderToolRandomization,
    clearFinderToolPagination
  };

  return (
    <KnightsContext.Provider value={context}>
      {children}
    </KnightsContext.Provider>
  );
}

export default KnightsContext;
