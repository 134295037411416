'use client';
import { createContext, useContext, useState } from 'react';
import { getTimeZones } from '@vvo/tzdb';
import * as ga from '../lib/ga';
import moment from 'moment-timezone';
import { getNewDate, createDateWithEDT } from 'shared/utils';
import { format } from 'date-fns-tz';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';

const EventsContext = createContext();

const validEventsFinderFilters = [
  'expertise',
  'event_types',
  'complexSearchEvent',
  'isBroadcomEvents',
  'start_date_filter',
  'end_date_filter',
  'admin_prod',
  'event_status',
  'p_prod',
  'regions'
];

export function EventsContextProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [relatedPageFiltering, setRelatedPageFiltering] = useState(0);
  const [randomizedRelated, setRandomizedRelated] = useState(null);
  const [randomizedExpert, setRandomizedExpert] = useState(null);
  const [randomizedNoExpert, setRandomizedNoExpert] = useState(null);
  const [randomizedRelatedNoExpert, setRandomizedRelatedNoExpert] =
    useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentDate, setCurrentDate] = useState(getNewDate({ isUTC0: true }));
  const [viewType, setViewType] = useState('calendar');
  const [view, setView] = useState('month');
  const [usersTimezoneLocation, setUsersTimezoneLocation] = useState(
    moment.tz.guess()
  );

  const [currentPageCalendar, setCurrentPageCalendar] = useState(0);
  const [currentSubTabCalendar, setCurrentSubTabCalendar] = useState('be');

  const allTimezones = getTimeZones();

  //0 - Daily, 1 - Weekly, 2 - Monthly, 3- Yearly
  const [selectedTab, setSelectedTab] = useState(2);

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function clearEventsFilters() {
    setFilters({});
  }
  function clearEventsFiltersExceptSearch() {
    if (filters.complexSearchEvent)
      setFilters({ complexSearchEvent: filters.complexSearchEvent });
    else setFilters({});
  }

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };

      if (prop === 'complexSearchEvent') {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      } else if (['isBroadcomEvents'].includes(prop)) {
        prop in objCopy ? delete objCopy[prop] : (objCopy[prop] = value);
      } else if (['start_date_filter', 'end_date_filter'].includes(prop)) {
        if (isValidDate(value) || value !== 'Invalid Date')
          objCopy[key] = [format(createDateWithEDT(value), 'yyyy-MM-dd')];
        else delete objCopy[prop];
      } else if (prop === 'events_date_range_filter') {
        const { from, to } = value;
        for (const { date, key } of [
          { date: from, key: 'start_date_filter' },
          { date: to, key: 'end_date_filter' }
        ]) {
          if ((date && isValidDate(date)) || date !== 'Invalid Date')
            objCopy[key] = [format(createDateWithEDT(date), 'yyyy-MM-dd')];
          else delete objCopy[key];
        }
      } else if (
        [
          'expertise',
          'event_types',
          'admin_prod',
          'admin_csp',
          'event_status',
          'p_prod',
          'regions'
        ].includes(prop)
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      }
      return objCopy;
    });
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function clearFinderToolRandomization() {
    setRandomizedExpert(null);
    setRandomizedNoExpert(null);
    setRandomizedRelated(null);
    setRandomizedRelatedNoExpert(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
    setRelatedPageFiltering(0);
  }

  const totalFilters = Object.entries(filters)?.reduce((total, filter) => {
    return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
  }, 0);

  const visibleTotalFilters = Object.keys(filters)?.includes('end_date_filter')
    ? totalFilters - 1
    : totalFilters;

  const {
    data: hasEventsInModeration = { hasEventsInModeration: false },
    refetch: refetchModerationEvents
  } = useQuery({
    queryKey: ['hasModerationEvents'],
    queryFn: async () => {
      const res = await getAPIClient().get('/dashboard/events');
      return res.data.hasEventsInModeration;
    }
  });

  const context = {
    randomizedRelated,
    setRandomizedRelated,
    relatedPageFiltering,
    setRelatedPageFiltering,
    currentPageFiltering,
    setCurrentPageFiltering,
    filters,
    setFilters,
    totalFilters,
    visibleTotalFilters,
    updateFilters,
    clickOnFilter,
    clearEventsFilters,
    clearEventsFiltersExceptSearch,
    randomizedExpert,
    setRandomizedExpert,
    randomizedNoExpert,
    setRandomizedNoExpert,
    randomizedRelatedNoExpert,
    setRandomizedRelatedNoExpert,
    selectedEvent,
    setSelectedEvent,
    currentDate,
    setCurrentDate,
    validEventsFinderFilters,
    view,
    setView,
    selectedTab,
    setSelectedTab,
    currentPageCalendar,
    setCurrentPageCalendar,
    currentSubTabCalendar,
    setCurrentSubTabCalendar,
    viewType,
    setViewType,
    isValidDate,
    allTimezones,
    usersTimezoneLocation,
    setUsersTimezoneLocation,
    clearFinderToolRandomization,
    clearFinderToolPagination,
    hasEventsInModeration,
    refetchModerationEvents
  };

  return (
    <EventsContext.Provider value={context}>{children}</EventsContext.Provider>
  );
}

export default EventsContext;
