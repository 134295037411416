'use client';
import { createContext, useContext, useRef, useState } from 'react';

import * as ga from '../lib/ga';

const OfferingsContext = createContext();

const validMarketplaceFinderFilters = [
  'expertise',
  'form_factor',
  'price',
  'operating_system',
  'complexSearchOffering'
];
export function OfferingsContextProvider({ children }) {
  const [filters, setFilters] = useState({});
  const [f, setF] = useState('nan');
  const [state, setState] = useState({ status: '', invalidFields: [] });
  const [currentPageFiltering, setCurrentPageFiltering] = useState(0);
  const [relatedPageFiltering, setRelatedPageFiltering] = useState(0);
  const [currentPageMarketplace, setCurrentPageMarketplace] = useState(0);
  const [marketplaceViewType, setMarketplaceViewType] = useState('list');
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [currentPageLogger, setCurrentPageLogger] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [randomizedExpert, setRandomizedExpert] = useState(null);
  const [randomizedRelated, setRandomizedRelated] = useState(null);
  const [randomizedNoExpert, setRandomizedNoExpert] = useState(null);
  const [randomizedRelatedNoExpert, setRandomizedRelatedNoExpert] =
    useState(null);

  const [onEditor, setOnEditor] = useState(false);

  const printOfferingPageRef = useRef();

  const clickOnFilter = (filter) => {
    ga.event({
      action: 'search',
      params: {
        search_term: 'filter (' + filter + ')'
      }
    });
  };

  function updateFilters(prop, value) {
    setFilters((prevFilters) => {
      let objCopy = { ...prevFilters };
      if (
        prop === 'price' ||
        prop === 'form_factor' ||
        prop === 'expertise' ||
        prop === 'operating_system'
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      }

      if (prop === 'complexSearchOffering') {
        objCopy[prop] = value;
        if (objCopy[prop].length === 0) {
          delete objCopy[prop];
        }
      }

      if (
        prop === 'per_page_admin_marketplace' ||
        prop === 'per_page_p_marketplace'
      ) {
        objCopy[prop] = value;
      }
      if (
        [
          'admin_visibility',
          'p_visibility',
          'admin_category',
          'p_category',
          'admin_formFactor',
          'p_formFactor',
          'admin_price',
          'p_price'
        ].includes(prop)
      ) {
        if (prop in objCopy) {
          if (objCopy[prop].includes(value)) {
            let index = objCopy[prop].indexOf(value);
            objCopy[prop].splice(index, 1);
            if (objCopy[prop].length === 0) delete objCopy[prop];
          } else {
            objCopy[prop].push(value);
          }
        } else {
          objCopy[prop] = [value];
        }
      }

      return objCopy;
    });
  }

  function clearOfferingsFilters() {
    setFilters({});
  }

  function clearOffFiltersExceptSearch() {
    if (filters.complexSearchOffering)
      setFilters({ complexSearchOffering: filters.complexSearchOffering });
    else setFilters({});
  }

  function clearFinderToolRandomization() {
    setRandomizedExpert(null);
    setRandomizedNoExpert(null);
    setRandomizedRelated(null);
    setRandomizedRelatedNoExpert(null);
  }

  function clearFinderToolPagination() {
    setCurrentPageFiltering(0);
    setRelatedPageFiltering(0);
  }

  const totalFilters = Object.entries(filters)
    ?.filter((el) => validMarketplaceFinderFilters.includes(el[0]))
    .reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  const visibleTotalFilters = totalFilters;

  const context = {
    filters,
    totalFilters,
    visibleTotalFilters,
    f,
    setF,
    state,
    setState,
    loadingInfo,
    setLoadingInfo,
    updateFilters,
    setFilters,
    clickOnFilter,
    clearOfferingsFilters,
    clearOffFiltersExceptSearch,
    currentPageFiltering,
    setCurrentPageFiltering,
    currentPageMarketplace,
    setCurrentPageMarketplace,
    marketplaceViewType,
    setMarketplaceViewType,
    currentPageLogger,
    setCurrentPageLogger,
    relatedPageFiltering,
    setRelatedPageFiltering,
    isOpen,
    setIsOpen,
    validMarketplaceFinderFilters,
    randomizedExpert,
    setRandomizedExpert,
    randomizedNoExpert,
    setRandomizedNoExpert,
    randomizedRelated,
    setRandomizedRelated,
    randomizedRelatedNoExpert,
    setRandomizedRelatedNoExpert,
    printOfferingPageRef,
    onEditor,
    setOnEditor,
    clearFinderToolRandomization,
    clearFinderToolPagination
  };

  return (
    <OfferingsContext.Provider value={context}>
      {children}
    </OfferingsContext.Provider>
  );
}

export default OfferingsContext;
