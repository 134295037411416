import axios from 'axios';
import { getBaseURL } from '../shared/utils';
import { parseCookies } from 'nookies';

export function getAPIClient(ctx?: any) {
  const api = axios.create({ baseURL: `${getBaseURL()}/api` });
  const cookieName =
    process?.env.NODE_ENV === 'development'
      ? 'next-auth.session-token'
      : '__Secure-next-auth.session-token';

  const { [cookieName]: token } = parseCookies(ctx);

  api.interceptors.request.use(
    (config) => {
      if (token) {
        if (!config.headers?.Authorization) {
          config.headers = {};
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return api;
}
